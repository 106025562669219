export default {
  routes: [
    {
      path: "/",
      name: "TopPage",
      component: () => import("@/views/TopPage.vue"),
      meta: {
        title: "top_page",
        desc: "top_page",
      }
    },
    {
      path: "/profile",
      name: "Profile",
      component: () => import("@/views/AboutMe.vue")
    },
    {
      path: "/articles",
      name: "Articles",
      component: () => import("@/views/listsOfArticle.vue")
    },
    {
      path: "/articles/article1",
      name: "article1",
      component: () => import("@/views/article-01-component.vue")
    },
    {
      path: "/articles/article2",
      name: "article2",
      component: () => import("@/views/article-02-component.vue")
    }
  ]
};